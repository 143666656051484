<template>
  <div class="viewFeedMechanism">
    <div class="header">
      <div>
        <span class="supplierName">{{feedParamsName}}</span>
        项目
      </div>
      <el-button type="primary" @click="exportFeed">导出</el-button>
    </div>
    <el-table
      class="ly_table"
      :data="viewFeedMechanismTable"
      :header-cell-style="{ background: '#F5F6F7', color: '#363E4D' }"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55" align="center" />
      <el-table-column prop="projectName" label="项目名称" align="center" />
      <el-table-column label="所在地区" align="center">
        <template v-slot="{row}">
          {{row.projectCountry?row.projectCountry:''}}
          {{row.projectProvince?'-'+row.projectProvince:''}}
          {{row.projectCity?'-'+row.projectCity:''}}
        </template>
      </el-table-column>
      <el-table-column prop="projectIndustryType" label="行业类别" align="center" />
      <el-table-column prop="projectFieldType" label="领域类别" align="center" />
      <el-table-column prop="supplierLinkman" label="联系人" align="center" />
      <el-table-column prop="supplierPhone" label="联系电话" align="center" />
      <el-table-column label="是否合作" align="center">
        <template v-slot="{row}">{{row.tender?'是':'否'}}</template>
      </el-table-column>
      <el-table-column prop="bidding" label="是否接洽" align="center">
        <template v-slot="{row}">{{row.bidding?'是':'否'}}</template>
      </el-table-column>
      <el-table-column label="操作" width="150" align="center">
        <template v-slot="{row}">
          <button-table @buttonClick="viewItem(row)" content="查看" icon="jr-iconjr-icon-eye" />
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      :current-page="queryInfo.pageNum"
      :page-sizes="[3, 5, 10]"
      :page-size="queryInfo.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
</template>

<script>
import { pagination } from '@/mixins/pagination'
import { getProjectSupplierModeById } from '@/api/numberProject'
import ButtonTable from '@/components/ButtonTable.vue'
import { exportData } from '@/utils/tool'
export default {
  name: 'ViewFeedMechanism',

  components: {
    ButtonTable,
  },

  mixins: [pagination],

  data() {
    return {
      viewFeedMechanismTable: [],
      queryInfo: {
        pageSize: 10,
        pageNum: 1,
      },
      ids: [],
    }
  },
  computed: {
    feedParams() {
      return this.$route.query.feedParams
    },
    feedParamsName() {
      return this.$route.query.feedParamsName
    },
    supplierLinkman() {
      return this.$route.query.supplierLinkman
    },
    supplierPhone() {
      return this.$route.query.supplierPhone
    },
  },
  created() {
    this.search()
  },

  methods: {
    async search() {
      Object.assign(this.queryInfo, { id: this.feedParams })

      const res = await getProjectSupplierModeById(this.queryInfo)

      if (res.code === 200) {
        this.viewFeedMechanismTable = res.data.list
        this.viewFeedMechanismTable.forEach((item) => {
          item.supplierLinkman = this.supplierLinkman
          item.supplierPhone = this.supplierPhone
        })
      }

      this.total = res.data.total
    },
    handleSelectionChange(val) {
      this.ids = val.map((item) => {
        return item.id
      })
    },
    viewItem(row) {
      this.$router.push({
        name: 'ProjectDataInfo',
        query: {
          projectDataType: 1,
          id: row.id,
          demandType: '需求机构模块',
        },
      })
    },
    exportFeed() {
      if (this.ids.length) {
        const params = {
          id: this.feedParams,
          companyCode: this.ids,
        }

        exportData(
          params,
          '/projectSupplier/proSuppExcel',
          'application/msexcel',
          this.search,
          '供给机构数据.xls'
        )
      } else {
        this.$message.warning('请选择要导出的数据')
      }
    },
  },
}
</script>

<style lang="less" scoped>
.viewFeedMechanism {
  background: #fff;
  padding: 20px;
  .header {
    display: flex;
    justify-content: space-between;
    .supplierName {
      color: #1e9fff;
      font-size: 18px;
      margin-right: 5px;
    }
  }
}
</style>
