var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"viewFeedMechanism"},[_c('div',{staticClass:"header"},[_c('div',[_c('span',{staticClass:"supplierName"},[_vm._v(_vm._s(_vm.feedParamsName))]),_vm._v(" 项目 ")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.exportFeed}},[_vm._v("导出")])],1),_c('el-table',{staticClass:"ly_table",attrs:{"data":_vm.viewFeedMechanismTable,"header-cell-style":{ background: '#F5F6F7', color: '#363E4D' }},on:{"selection-change":_vm.handleSelectionChange}},[_c('el-table-column',{attrs:{"type":"selection","width":"55","align":"center"}}),_c('el-table-column',{attrs:{"prop":"projectName","label":"项目名称","align":"center"}}),_c('el-table-column',{attrs:{"label":"所在地区","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.projectCountry?row.projectCountry:'')+" "+_vm._s(row.projectProvince?'-'+row.projectProvince:'')+" "+_vm._s(row.projectCity?'-'+row.projectCity:'')+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"projectIndustryType","label":"行业类别","align":"center"}}),_c('el-table-column',{attrs:{"prop":"projectFieldType","label":"领域类别","align":"center"}}),_c('el-table-column',{attrs:{"prop":"supplierLinkman","label":"联系人","align":"center"}}),_c('el-table-column',{attrs:{"prop":"supplierPhone","label":"联系电话","align":"center"}}),_c('el-table-column',{attrs:{"label":"是否合作","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.tender?'是':'否'))]}}])}),_c('el-table-column',{attrs:{"prop":"bidding","label":"是否接洽","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.bidding?'是':'否'))]}}])}),_c('el-table-column',{attrs:{"label":"操作","width":"150","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('button-table',{attrs:{"content":"查看","icon":"jr-iconjr-icon-eye"},on:{"buttonClick":function($event){return _vm.viewItem(row)}}})]}}])})],1),_c('el-pagination',{attrs:{"current-page":_vm.queryInfo.pageNum,"page-sizes":[3, 5, 10],"page-size":_vm.queryInfo.pageSize,"layout":"total, sizes, prev, pager, next, jumper","total":_vm.total},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }